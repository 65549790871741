import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { InventoryClient } from "../../../clients/InventoryClient"
import InventoryResultCard from "../Inventory/InventoryResultCard"
import AliceCarousel from "react-alice-carousel"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import DealerDefaultMessage from "./DefaultSection"
import Icon from "../../atoms/Icon"
import { openFavoritesContactDealerModal } from "../../../contexts/Contact/actions"
import { ContactContext } from "../../../contexts/Contact"
import Share from "../../atoms/Icon/Share"
import { css } from "@emotion/react"
import Lottie from "react-lottie-player"
import lottieJson from "../../../animations/14717-sedan-car-animation.json"

const DealerFavorites: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const { _ } = useContext(LanguageContext)
  const [{}, dispatchContactForm] = useContext(ContactContext)
  const [isLoading, setVehiclesLoading] = useState<boolean>(false)
  const { favoritedVehicles } = useContext(FavoritesContext)[0]

  useEffect(() => {
    const updateVehicleData = async () => {
      setVehiclesLoading(true)

      let filteredVehicles = [] // Define filteredVehicles here

      if (Array.isArray(favoritedVehicles) && favoritedVehicles.length > 0) {
        const vehicleData = await InventoryClient.get({
          vin: favoritedVehicles.join(","),
          include: ["accessories"],
          dealer: dealer.DealerCode,
        })

        if (
          vehicleData &&
          Array.isArray(vehicleData.vehicles) &&
          vehicleData.vehicles.length > 0
        ) {
          filteredVehicles = vehicleData.vehicles.filter(
            vehicle => vehicle.dealer === dealer.DealerCode,
          )
          if (filteredVehicles.length > 0) {
            setVehicleItems(
              filteredVehicles.map((vehicle, i) => (
                <div css={[tw`min-w-[380px]`, tw`lg:(max-w-[420px])`]}>
                  <div
                    css={[
                      tw`mx-auto flex-nowrap flex`,
                      tw`md:(mx-12)`,
                      tw`lg:(mx-4)`,
                      tw`pb-[516px] mb-[-500px]`,
                    ]}
                  >
                    <InventoryResultCard
                      vehicle={vehicle}
                      css={[tw`bg-white`]}
                      key={`favorited-vehicle-${i}-${vehicle?.vin}`}
                      location={"favorite inventory"}
                      index={i}
                      fromDealer={true}
                    />
                  </div>
                </div>
              )),
            )
          }
        }
      }

      // If no vehicles found, set a default message as an array.
      if (filteredVehicles.length === 0) {
        setVehicleItems([
          <div key="default-message">
            <DealerDefaultMessage />
          </div>,
        ])
      }

      setVehiclesLoading(false)
    }

    // Always call updateVehicleData, even when there are no favoritedVehicles
    updateVehicleData()
  }, [favoritedVehicles])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const renderPrevButtonBottom = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute left-[30%] -bottom-6 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          tw`md:(-left-6 top-[45%])`,
          tw`lg:(-left-14)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "left arrow",
          })
        }
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButtonBottom = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute right-[30%] -bottom-6 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          tw`md:(-right-6 top-[45%])`,
          tw`lg:(-right-14)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "right arrow",
          })
        }
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const redUnderlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `
  const responsiveInventory = {
    0: { items: 1 },
    1200: { items: 2 },
  }
  const [vehicleItems, setVehicleItems] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section
      aria-label="Favorited Vehicles Section"
      css={[
        tw`flex flex-col justify-start gap-2`,
        tw`lg:(flex-row justify-start items-start w-full)`,
      ]}
    >
      <div
        css={[
          tw`w-full text-3xl mt-4 font-light text-center`,
          tw`lg:(text-3xl text-left ml-8 mt-8 max-w-[300px] min-w-[300px])`,
          tw`2xl:(ml-0)`,
          "letter-spacing: 6px;",
        ]}
      >
        {_("Favorites from")} {dealer?.Name}
      </div>
      <section
        aria-label="Favorited Vehicles Section"
        css={[
          tw`text-center w-full mx-auto pt-8`,
          tw`sm:(w-[600px])`,
          tw`xl:(w-[800px])`,
        ]}
      >
        {isLoading && (
          <div
            css={[
              tw`z-10 bg-opacity-70 w-full flex items-center justify-center h-96`,
            ]}
          >
            <div>
              <span
                css={[
                  tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                ]}
              >
                {_("Hang tight, we're fetching your favorited vehicles.")}
              </span>
              <div>
                <Lottie loop animationData={lottieJson} play />
              </div>
            </div>
          </div>
        )}
        <div
          css={[
            favoritedVehicles?.length === 0
              ? tw`mb-[200px] mx-auto justify-center`
              : tw`mb-0 mx-auto justify-center`,
          ]}
        >
          <div
            css={[
              tw`relative mx-0`,
              tw`md:(mx-9)`,
              tw`xl:(mx-auto max-w-[800px])`,
              tw`2xl:(mx-auto max-w-5xl)`,
            ]}
            id={"favorites-carousel"}
          >
            <AliceCarousel
              mouseTracking={false}
              touchTracking={false}
              items={vehicleItems}
              disableDotsControls
              responsive={responsiveInventory}
              paddingLeft={0}
              paddingRight={0}
              controlsStrategy="alternate"
              renderPrevButton={renderPrevButtonBottom}
              renderNextButton={renderNextButtonBottom}
              activeIndex={activeIndex}
              onSlideChanged={({ item }) => setActiveIndex(item)}
            />
          </div>
          {!isLoading &&
            vehicleItems[0] &&
            vehicleItems[0].key !== "default-message" && (
              <button
                onClick={() =>
                  dispatchContactForm(openFavoritesContactDealerModal())
                }
                css={[
                  tw`flex mx-auto justify-center items-center gap-2 mt-6`,
                  tw`lg:(mt-0 ml-6)`,
                ]}
              >
                <Share css={tw`w-6`} />
                <span css={[tw`relative py-0.5`, redUnderlineAnimation]}>
                  {_("Share Favorites")}
                </span>
              </button>
            )}
        </div>
      </section>
    </section>
  )
}

export default DealerFavorites
